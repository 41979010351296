import React from "react";
import { graphql } from "gatsby";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes
} from "../lib/helpers";
import BlogPostPreviewGrid from "../components/blog-post-preview-grid";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

import '../styles/index.css';

//isFeatured: {eq: true} -- add this to the filter below if you need it
export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      hero {
        asset {
          url
        }
      }
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null }} 
    ) {
      edges {
        node {
          id
          publishedAt
          categories {
            title
            description
          }
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    categories: allSanityCategory {
      edges {
        node {
          id
          title
          description
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }


  const site = (data || {}).site;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
      .filter(post => post.categories.length)
    : [];

  // const featuredPosts = postNodes.filter((node) => node.isFeatured);
  const crowdfundingPosts = postNodes
    .filter((node) => node.categories[0].title === 'Crowdfunding')
    .slice(0, 3);
  const crowdgrantingPosts = postNodes
    .filter((node) => node.categories[0].title === 'Crowdgranting')
    .slice(0, 3);
  const placemakingPosts = postNodes
    .filter((node) => node.categories[0].title === 'Placemaking')
    .slice(0, 3);
  const peoplePosts = postNodes
    .filter((node) => node.categories[0].title === 'People & Places')
    .slice(0, 3);

  const categories = (data || {}).categories ? mapEdgesToNodes(data.categories).map((category) => ({ "id": category.id, "title": category.title, "description": category.description }))
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
        image={site.hero}
      />
      <div class="headerImageSection" style={{ backgroundImage: `url(${data.site.hero.asset.url})` }}>
        {/* <img src={data.site.hero.asset.url} /> */}
        <div class="headerImageCopy">
          <h1>Welcome to <img src="https://cdn.sanity.io/images/kyxjcfj0/production/f07f38bcde93516a3946dca0c4157272c138df81-2248x607.png" alt="Patronicity Logo" /> Resources</h1>
        </div>
      </div>
      <Container>
        <p class="headerCopy">We are invested in helping you build vibrant communities. We’ve developed resources on placemaking, crowdfunding, and crowdgranting to provide you with inspiration, how-to guides, research, and more, to support your efforts to better where you live, work, and play.</p>
        <p class="headerCopy">When you’re ready to get started, we’re here to help you get your project off the ground! Whether it’s a grassroots crowdfunding campaign to build a new playground or grow your business, or a new statewide grant program to support local community and economic development – we’re here to help.</p>

        <div>
          <div class="mc_embed_signup">
            <form action="https://benchconsulting.us19.list-manage.com/subscribe/post?u=9987e252175671e64424db8c2&amp;id=e69ee2caba" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
              <div id="mc_embed_signup_scroll">
                <label for="mce-EMAIL">Transforming communities in your inbox.</label>
                <div hidden="true"><input type="hidden" name="tags" value="6241040"></input></div>
                <input type="email" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required></input>
                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                <div class="mc-hidden" aria-hidden="true"><input type="text" name="b_9987e252175671e64424db8c2_e69ee2caba" tabindex="-1" value=""></input></div>
                <div class="clear btn-container"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></input></div>
              </div>
            </form>
          </div>
        </div>

        {postNodes && (
          <BlogPostPreviewGrid
            title={categories[3].title}
            catId={categories[3].id}
            description={categories[3].description}
            nodes={postNodes
              .filter((node) => node.categories[0].title === categories[3].title)
              .slice(0, 3)}
            browseMoreHref="/explore/"
          />
        )}
        {postNodes && (
          <BlogPostPreviewGrid
            title={categories[2].title}
            catId={categories[2].id}
            description={categories[2].description}
            nodes={postNodes
              .filter((node) => node.categories[0].title === categories[2].title)
              .slice(0, 3)}
            browseMoreHref="/explore/"
          />
        )}
        {postNodes && (
          <BlogPostPreviewGrid
            title={categories[1].title}
            catId={categories[1].id}
            description={categories[1].description}
            nodes={postNodes
              .filter((node) => node.categories[0].title === categories[1].title)
              .slice(0, 3)}
            browseMoreHref="/explore/"
          />
        )}
        {postNodes && (
          <BlogPostPreviewGrid
            title={categories[0].title}
            catId={categories[0].id}
            description={categories[0].description}
            nodes={postNodes
              .filter((node) => node.categories[0].title === categories[0].title)
              .slice(0, 3)}
            browseMoreHref="/explore/"
          />
        )}

        {/* <h3 class="headerCtaCopy">We’d love to hear from you. <a target="_blank" href="mailto:info@patronicity.com"><button class="headerCtaButton">Contact us</button></a></h3> */}

        {/* <div id="mc_embed_signup">
          <form action="https://benchconsulting.us19.list-manage.com/subscribe/post?u=9987e252175671e64424db8c2&amp;id=e69ee2caba" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
              <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required></input>
              <div aria-hidden="true">
                <input type="text" name="b_9987e252175671e64424db8c2_e69ee2caba" tabindex="-1" value=""></input>
              </div>
              <div class="clear btn-container"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></input></div>
            </div>
          </form>
        </div> */}
      </Container>
    </Layout>
  );
};

export default IndexPage;
